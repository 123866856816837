import { Box } from "@mui/material";
import React from "react";
import ChargingStartPage from "../../components/chargingStart/ChargingStartPage";

const ChargingStart = () => {
  return (
    <Box>
      <ChargingStartPage />
    </Box>
  );
};

export default ChargingStart;
