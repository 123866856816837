import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../../API/endpoints";

// Initial state (taken outside the slice)
const initialState = {
  deviceDetails: null,
  deviceDetailsStatus: "idle",
  sendOtpStatus: "idle",
  verifyOtpStatus: "idle",
  resendOtpStatus: "idle",
  verifyOtpResponse: null,
  makePaymentStatus: "idle", // Add this line
  makePaymentResponse: null, // Add this line
  error: null,
};

// Thunks for async API calls
export const fetchDeviceDetails = createAsyncThunk(
  "directPayment/fetchDeviceDetails",
  async (deviceID, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${endPoints.getDeviceDetails}/${deviceID}`
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const sendOTP = createAsyncThunk(
  "directPayment/sendOTP",
  async (phoneNumber, { rejectWithValue }) => {
    try {
      const response = await axios.post(endPoints.sendOTP, {
        phone: phoneNumber,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const resendOTP = createAsyncThunk(
  "directPayment/resendOTP",
  async (phoneNumber, { rejectWithValue }) => {
    try {
      const response = await axios.post(endPoints.resendOTP, {
        phone: phoneNumber,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const verifyOTP = createAsyncThunk(
  "directPayment/verifyOTP",
  async ({ phoneNumber, otp }, { rejectWithValue }) => {
    try {
      const response = await axios.post(endPoints.verifyOTP, {
        phone: phoneNumber,
        otp: otp,
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const makePayment = createAsyncThunk(
  "directPayment/makePayment",
  async ({ amount, deviceId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        endPoints.paytmInit,
        { amount: amount, deviceId: deviceId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Slice to handle the API state
const apiSlice = createSlice({
  name: "directPayment",
  initialState,
  reducers: {
    // resetState: (state) => {
    //   state.deviceDetails = null;
    //   state.deviceDetailsStatus = "idle";
    //   state.sendOtpStatus = "idle";
    //   state.verifyOtpStatus = "idle";
    //   state.resendOtpStatus = "idle";
    //   state.error = null;
    // },
  },
  extraReducers: (builder) => {
    // Handle fetchDeviceDetails
    builder
      .addCase(fetchDeviceDetails.pending, (state) => {
        state.deviceDetailsStatus = "loading";
      })
      .addCase(fetchDeviceDetails.fulfilled, (state, action) => {
        state.deviceDetails = action.payload;
        state.deviceDetailsStatus = "succeeded";
      })
      .addCase(fetchDeviceDetails.rejected, (state, action) => {
        state.deviceDetailsStatus = "failed";
        state.error = action.payload;
      });

    // Handle sendOTP
    builder
      .addCase(sendOTP.pending, (state) => {
        state.sendOtpStatus = "loading";
      })
      .addCase(sendOTP.fulfilled, (state) => {
        state.sendOtpStatus = "succeeded";
      })
      .addCase(sendOTP.rejected, (state, action) => {
        state.sendOtpStatus = "failed";
        state.error = action.payload;
      });

    // Handle resendOTP
    builder
      .addCase(resendOTP.pending, (state) => {
        state.resendOtpStatus = "loading";
      })
      .addCase(resendOTP.fulfilled, (state) => {
        state.resendOtpStatus = "succeeded";
      })
      .addCase(resendOTP.rejected, (state, action) => {
        state.resendOtpStatus = "failed";
        state.error = action.payload;
      });

    // Handle verifyOTP
    builder
      .addCase(verifyOTP.pending, (state) => {
        state.verifyOtpStatus = "loading";
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.verifyOtpResponse = action.payload; // Store the response data
        state.verifyOtpStatus = "succeeded";
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.verifyOtpStatus = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(makePayment.pending, (state) => {
        state.makePaymentStatus = "loading";
      })
      .addCase(makePayment.fulfilled, (state, action) => {
        state.makePaymentResponse = action.payload; // Store the response data
        state.makePaymentStatus = "succeeded";
      })
      .addCase(makePayment.rejected, (state, action) => {
        state.makePaymentStatus = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetState } = apiSlice.actions;
export default apiSlice.reducer;
