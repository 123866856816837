import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "#fff",
  boxShadow: 24,
  padding: "24px 16px",
  borderRadius: "8px",
};

const StatusModal = ({ open, handleClose, deviceId }) => {
  const navigate = useNavigate();
  const [timer, setTimer] = React.useState(3); // Initial countdown set to 3 seconds

  // Countdown and redirect after the timer hits 0
  React.useEffect(() => {
    if (open) {
      const countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(countdown); // Stop the countdown
            navigate(`/${deviceId}`); // Navigate to home page when the timer reaches 0
          }
          return prev - 1; // Decrease the timer by 1
        });
      }, 1000); // Update the timer every 1 second

      // Cleanup the interval when the component unmounts or modal is closed
      return () => clearInterval(countdown);
    }
  }, [open, navigate]);

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              width: "48px",
              height: "48px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 0, 0, 0.1)", // Light red background
              margin: "0 auto 16px", // Center the icon and add bottom margin
            }}
          >
            <span
              className="material-symbols-outlined"
              style={{ color: "red", fontSize: "32px" }}
            >
              error_outline
            </span>
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color="#2F2F2F"
            textAlign={"center"}
            sx={{ fontFamily: "mySecondFont", marginBottom: "8px" }}
          >
            Charger Unavailable!
          </Typography>
          <Typography
            id="modal-modal-description"
            color="#666"
            fontSize="14px"
            textAlign={"center"}
            sx={{ fontFamily: "mySecondFont" }}
          >
            Sorry, currently this charger is not available. Please try again
            later or select a different charger.
          </Typography>
          <Typography
            sx={{ mt: 2, textAlign: "center", color: "#666", fontSize: "12px" }}
          >
            Redirecting to home page in {timer} seconds...
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default StatusModal;
