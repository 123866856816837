import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { Route, Routes } from "react-router-dom";
import { routes } from "./routes/routes";
import { Toaster } from "sonner";

function App() {
  return (
    <>
      <Header />
      <Routes>
        {routes.map((item) => (
          <Route path={item.path} element={item.element} />
        ))}
      </Routes>
      <Toaster richColors position="top-right" />
      <Footer />
    </>
  );
}

export default App;
