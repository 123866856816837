import { Box } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const { deviceId, countryCode } = useParams();

  return (
    <Box
      className="page"
      sx={{
        width: "100%",
        padding: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#fff",
      }}
      // onClick={() => navigate(`/${countryCode}/${deviceId}`)}
    >
      <img style={{ width: "30px" }} src="/images/mainLogo.png" />
    </Box>
  );
};

export default Header;
