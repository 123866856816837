import { Box } from "@mui/material";
import React from "react";
import OTPVerify from "../../components/verificationPage/OTPVerify";

const OTPVerification = () => {
  return (
    <Box>
      <OTPVerify />
    </Box>
  );
};

export default OTPVerification;
