import { configureStore } from "@reduxjs/toolkit";
import directPaymentReducer from "./action/directPaymentSlice";

const store = configureStore({
  reducer: {
    directPayment: directPaymentReducer,
  },
});

export default store;
