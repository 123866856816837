import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "#fff",
  boxShadow: 24,
  padding: "8px 16px 0px 16px",
  borderRadius: "8px",
};

const DownloadAppModal = ({ open, handleClose }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <span
              className="material-symbols-outlined"
              style={{ color: "#8A0000" }}
              onClick={handleClose}
            >
              cancel
            </span>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img style={{ width: "40px" }} src="/images/modalLogo.png" alt="" />
            <Typography
              mt={"4px"}
              color="#292D33"
              fontFamily={"'Montserrat', sans-serif"}
              letterSpacing={"1.28px"}
              fontWeight={600}
              fontSize={"16px"}
            >
              SCAN. PAY. CHARGE.
            </Typography>
            <img style={{ width: "240px" }} src="/images/phoneImg.png" alt="" />
          </Box>
          <Box
            sx={{
              background:
                "linear-gradient(100deg, #FF7B52 -34.07%, #E50000 104.51%), #292D33",
              padding: "24px 16px",
              marginLeft: "-16px",
              marginRight: "-16px",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <Typography
              fontSize={"24px"}
              fontFamily={"'Montserrat', sans-serif"}
              textAlign={"center"}
              fontWeight={700}
              color="#fff"
              textTransform={"capitalize"}
            >
              Download the 1C app
              <br /> to find available <br /> chargers near-by
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <Typography
                textAlign={"center"}
                color="#fcfcfc"
                fontFamily={"mySecondFont"}
                fontSize={"16px"}
              >
                Download from
              </Typography>
              <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <a
                  href="https://apps.apple.com/in/app/1c-ev-charging/id6478754214"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    sx={{
                      padding: "14px 12px",
                      background: "#090909",
                      width: "100%",
                      borderRadius: "8px",
                    }}
                  >
                    <img
                      style={{ width: "126px", height: "30px" }}
                      src="/images/appstore.png"
                      alt=""
                    />
                  </Button>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=in.one.charging&hl=en_IN"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    sx={{
                      padding: "14px 12px",
                      background: "#090909",
                      width: "100%",
                      borderRadius: "8px",
                    }}
                  >
                    <img
                      style={{ width: "126px", height: "30px" }}
                      src="/images/playstore.png"
                      alt=""
                    />
                  </Button>
                </a>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DownloadAppModal;
