import { Box, Container, Typography } from "@mui/material";
import React from "react";
import DeviceDetails from "../Cards/DeviceDetails";

const MainPage = () => {
  return (
    <Box className="page" sx={{ mt: "4px", background: "#fff" }}>
      <Container>
        {/* Top - Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img style={{ width: "32px" }} src="/images/bolt.png" alt="" />
          <Typography
            fontSize={"32px"}
            fontFamily={"'Montserrat', sans-serif"}
            fontWeight={700}
            sx={{
              background: "linear-gradient(95deg, #FF7B52 5.17%, #E50000 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Welcome to 1C
          </Typography>
          <Typography
            mt={"4px"}
            color="#292D33"
            fontFamily={"'Montserrat', sans-serif"}
            letterSpacing={"1.12px"}
            fontWeight={600}
            fontSize={"14px"}
          >
            SCAN. PAY. CHARGE.
          </Typography>
          <Box sx={{ mt: "8px" }}>
            <img
              style={{ maxWidth: "150px", width: "100%" }}
              src="/images/upi.png"
              alt=""
            />
          </Box>
        </Box>

        {/* Device - Detail - Card */}
        <Box sx={{ marginTop: "32px" }}>
          <DeviceDetails />
        </Box>
      </Container>
    </Box>
  );
};

export default MainPage;
