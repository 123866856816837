import { Box } from "@mui/material";
import React from "react";
import PhoneVerify from "../../components/verificationPage/PhoneVerify";

const VerificationPage = () => {
  return (
    <Box>
      <PhoneVerify />
    </Box>
  );
};

export default VerificationPage;
