import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import DownloadAppModal from "../Modals/DownloadAppModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDeviceDetails } from "../../redux/action/directPaymentSlice";
import { countryToCurrency } from "../../utils/countryCodes";
import { currencySymbols } from "../../utils/currencySymbols";

const DetailBox = ({ label, value, children }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Typography fontSize="14px" fontFamily="myThirdFont" color="#2F2F2F">
      {label}
    </Typography>
    <Typography
      fontSize="18px"
      fontFamily="mySecondFont"
      color="#1C1C1C"
      sx={{ display: "flex", alignItems: "center" }}
    >
      {children || value}
    </Typography>
  </Box>
);

const DeviceDetails = () => {
  const [open, setOpen] = React.useState(false);
  // const [status, setStatus] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { deviceId, countryCode } = useParams();

  const countryCodeName = location.pathname
    .split("/")
    .find((part) => part.length === 2); // Assuming country codes are 2 letters
  const currency = countryToCurrency[countryCodeName] || "INR"; // Default to INR if not found
  const currencySymbol = currencySymbols[currency] || "₹"; // Default to ₹

  // const deviceId = "NEWZEADC87963";
  // console.log("device:", deviceId);

  const deviceDetails = useSelector(
    (state) => state.directPayment.deviceDetails
  );
  const deviceDetailsStatus = useSelector(
    (state) => state.directPayment.deviceDetailsStatus
  );
  const deviceDetailsError = useSelector(
    (state) => state.directPayment.deviceDetailsError
  );

  console.log(deviceDetails);

  // Derived status from deviceDetails
  const status =
    (deviceDetails?.status === "Available" &&
      deviceDetails?.connectorType === "AC Socket") ||
    deviceDetails?.status === "Preparing";

  useEffect(() => {
    if (deviceId) {
      dispatch(fetchDeviceDetails(deviceId)); // Fetch device details on component mount
    }
  }, [deviceId, dispatch]);

  // useEffect(() => {
  //   if (deviceDetails) {
  //     // Update status based on fetched device details, for example
  //     setStatus(
  //       deviceDetails?.status === "Available" &&
  //         deviceDetails?.connectorType === "AC Socket"
  //     );
  //   }
  // }, [deviceDetails]);

  const handlePageChange = () => {
    navigate(`/verification/${countryCode}/${deviceId}`);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Automatically open modal if status is false or no device details after 1 second
  useEffect(() => {
    if (!status || !deviceDetails) {
      const timer = setTimeout(() => {
        setOpen(true);
      }, 1000);
      return () => clearTimeout(timer); // Clear the timeout if component unmounts
    }
  }, [status, deviceDetails]);

  return (
    <>
      <Box
        sx={{
          borderRadius: "8px",
          borderTop: status ? "4px solid #00733D" : "4px solid #951A1D",
          background: status
            ? "linear-gradient(180deg, rgba(0, 115, 61, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%)"
            : "linear-gradient(180deg, rgba(149, 26, 29, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%)",
          padding: "24px 16px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <Typography
          color="#292D33"
          textAlign="center"
          fontFamily="mySecondFont"
          fontSize="14px"
          textTransform="uppercase"
        >
          Device details
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DetailBox
            label="Device ID"
            value={deviceDetails?.deviceId || "N/A"}
          />
          <Box
            sx={{
              padding: "8px 0px",
              width: "112px",
              border: !status ? "1px solid #951A1D" : "1px solid #00733D",
              background: !status
                ? "rgba(149, 26, 29, 0.10)"
                : "rgba(2, 121, 0, 0.10)",
              borderRadius: "50px",
              color: !status ? "#951A1D" : "#00733D",
              fontSize: "16px",
              fontFamily: "mySecondFont",
              textTransform: "capitalize",
              textAlign: "center",
            }}
          >
            {!status ? "Unavailable" : "Available"}
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: "24px" }}>
          <DetailBox
            label="Charger type"
            value={deviceDetails?.chargingPointType || "N/A"}
          />
          <DetailBox
            label="Connector type"
            value={deviceDetails?.connectorType || "N/A"}
          />
          {/* <img src="/images/ctype.png" alt="" /> */}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: " 44px" }}>
          <DetailBox
            label="Supply"
            value={deviceDetails?.power ? `${deviceDetails.power} kW` : "N/A"}
          />
          <DetailBox
            label="Unit Cost"
            value={
              deviceDetails?.unitCost
                ? `${currencySymbol}${deviceDetails.unitCost}/kWh`
                : "N/A"
            }
          />
        </Box>

        <Button
          onClick={handlePageChange}
          sx={{
            background: "#222",
            borderRadius: "8px",
            padding: "12px 24px",
            width: "100%",
            fontFamily: "mySecondFont",
            color: "#fcfcfc",
            letterSpacing: "1.28px",
            ":disabled": {
              opacity: "0.25",
              color: "#FCFCFC",
            },
          }}
          disabled={!status}
        >
          Proceed
        </Button>
      </Box>

      {!status && (
        <Button
          sx={{
            background:
              "linear-gradient(94deg, #FF7B52 -20.04%, #E50000 122.31%)",
            borderRadius: "8px",
            padding: "12px 24px",
            width: "100%",
            fontFamily: "mySecondFont",
            color: "#fcfcfc",
            letterSpacing: "1.28px",
            mt: "16px",
            mb: "24px",
          }}
          onClick={handleOpen}
        >
          EXPLORE NEAR BY CHARGERS
        </Button>
      )}

      {/* Download - Modal */}
      <DownloadAppModal open={open} handleClose={handleClose} />
    </>
  );
};

export default DeviceDetails;
